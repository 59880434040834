const ClearButton = ({ numbers, onClick }) => {

  return (
    <button
      type="button"
      className="clear-button"
      onClick={() => onClick([])}
      disabled={numbers.length === 0}
    >
      清除
    </button>
  );
};

export default ClearButton;