import React from 'react';

const NumberSelectedArea = ({ text }) => {
    return (
      <div className={'news-ticker'}>
        <span>{text}</span>
      </div>
    );
  };
  
  export default NumberSelectedArea;