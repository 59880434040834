import Ball from './Ball';
import IconBall from './IconBall';
import { v4 as uuidv4 } from 'uuid';
import NumberSelectedArea from './NumberSelectedArea';
const BallSelector = ({selectedNumbers, onSelect, totalBallsCount, ballNumberEachRow, lottoType} ) => {

  const handleBallClick = (number) => {
    if (selectedNumbers.includes(number)) {
      onSelect(selectedNumbers.filter((n) => n !== number));
    } else if (selectedNumbers.length < 15) {
      onSelect([...selectedNumbers, number]);
    }
  };

  const rows = [];
  let row = [];

  for (let i = 0; i <= totalBallsCount; i++) {
    if (i === 0 && ballNumberEachRow !== 3) {
      row.push(
        <IconBall 
          key={uuidv4()}
          lottoType={lottoType}
        />
      )
    } else if (i > 0) {
      row.push(
        <Ball
          key={uuidv4()}
          number={i}
          selected={selectedNumbers.includes(i)}
          onClick={handleBallClick}
        />
      );
    }
  
    if (ballNumberEachRow === 3 && (i % 3 === 0) || i === totalBallsCount) {
      rows.push(
        <div key={uuidv4()} className="ball-row">
          {row}
        </div>
      );
      row = [];
    } else if (ballNumberEachRow !== 3 && i % ballNumberEachRow === 9) {
      rows.push(
        <div key={uuidv4()} className="ball-row">
          {row}
        </div>
      );
      row = [];
    }
  }
  

  return (
    <div>
    <div className="ball-selector">
      <div className="ball-container">
        {rows}
      </div>
    </div>
        <NumberSelectedArea text={selectedNumbers.map(number => number.toString().padStart(2, '0')).join('.')} />
    </div>
  );
};

export default BallSelector;


