const SubmitButton = ({ onClick }) => {
  
  return (
    <button className="submit-button" onClick={onClick}>
      提交
    </button>
  );
};

export default SubmitButton;
