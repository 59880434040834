import PropTypes from 'prop-types';

const OrderArea = ({ gameNameDispaly, multiplier, setMultiplier, unitPrice, totalAmount, balance }) => {

  return (
    <div className="order-area">
      <div className="order-input">
        <span>{gameNameDispaly} ×</span>
        <input
          type="number"
          inputMode="decimal"
          step={0.1}
          value={multiplier}
          onChange={(e) => {
            const floatValue = parseFloat(e.target.value);
            if (!isNaN(floatValue)) {
              setMultiplier(floatValue);
            } else if (e.target.value === "") {
              setMultiplier("");
            }
          }}
          onBlur={(e) => {
            if (!e.target.value || isNaN(parseFloat(e.target.value))) {
              setMultiplier(1);
            }
          }}
        />
        <span>1={unitPrice}元</span>
        <br />
        <p>您的餘額為 {balance} 元。</p>
        <p>總共需支付 {totalAmount} 元。</p>
      </div>
    </div>
  );
  // };
}

OrderArea.propTypes = {
  gameNameDispaly: PropTypes.string.isRequired,
};

export default OrderArea;