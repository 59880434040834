import React from 'react';

const IconBall = ({ lottoType }) => {

    let BALL_BACKGROUND_IMAGE = ''

    switch (lottoType) {
        case "大樂透":
            BALL_BACKGROUND_IMAGE = require('../../static/biglotto.webp');
            break;
        case "六合彩":
            BALL_BACKGROUND_IMAGE = require('../../static/six.png');
            break;
        case "今彩539":
            BALL_BACKGROUND_IMAGE = require('../../static/539.webp');
            break;
        default:
            BALL_BACKGROUND_IMAGE = require('../../static/biglotto.webp');
    }

  return (
    <button className='ball'>
        <img
        className='ball-icon-img'
        src={BALL_BACKGROUND_IMAGE}
        alt="lotto type" />
    </button>
  );
};

export default IconBall;