// utils.js
export const calculateCost = (lottoType, gameName, selectedNumbers, multiplier, unitPrice , columnBars) => {
  switch (gameName) {
    case '全車':
      return calculateCarCost(selectedNumbers, multiplier, unitPrice, lottoType);
    case '234包車':
      return calculateFiveCarCost(selectedNumbers, multiplier);
    case '大二三四':
      return calculate234Cost(selectedNumbers , columnBars, multiplier ,unitPrice );    
    // add additional cases for other game modes as needed
    case '天一':
      return calculateTainOneCost(selectedNumbers, multiplier, unitPrice, lottoType);
    
    default:
      return 0;
  }
};

const calculateCarCost = (selectedNumbers, multiplier, unitPrice, lottoType) => {
  const numSelected = selectedNumbers.length;
  let totalAmount = Math.round(numSelected * multiplier * unitPrice * (lottoType === '今彩539' ? 38 : 48));
  totalAmount = isNaN(totalAmount) ? 0 : totalAmount;
  return totalAmount;
};

const calculateTainOneCost = (selectedNumbers, multiplier, unitPrice, lottoType) => {
  const numSelected = selectedNumbers.length;
  let totalAmount = Math.round(numSelected * multiplier * unitPrice);
  totalAmount = isNaN(totalAmount) ? 0 : totalAmount;
  return totalAmount;
};

const calculateFiveCarCost = (selectedNumbers, multiplier) => {
  // TODO: implement calculation for FiveCar game mode
  return 0;
};

export const calculateTaiSpecialCost = (selectedNumbers, multiplier, unitPrice) => {
  const numSelected = selectedNumbers.length;
  console.log(numSelected);
  let totalAmount = Math.round(numSelected * multiplier * unitPrice );
  totalAmount = isNaN(totalAmount) ? 0 : totalAmount;
  return totalAmount;
};

export const calculate234Cost = (branch, multiplier, finalUnitPrice) => {
  // TODO: implement calculation for Big234 game mode
  let totalAmount = 0;
  totalAmount += Math.round(branch[0] * finalUnitPrice[0] * multiplier[0]);
  totalAmount += Math.round(branch[1] * finalUnitPrice[1] * multiplier[1]);
  totalAmount += Math.round(branch[2] * finalUnitPrice[2] * multiplier[2]);

  return totalAmount;
};


export const calculateNumberOfStarCombination = (columnBars, selected, star) => {
  
  const container = [];
  if (columnBars.length >= 1){   
    for (let a = 0; a < columnBars.length -1; a++) { // a柱
      for (let b = a + 1; b < columnBars.length ; b++) { // columnBars[a+1] 的柱子，命名為b柱

        if (star === 2) {

          for (let l = 0; l < columnBars[a].flat().length; l++) { // a柱的號碼
            for (let m = 0; m < columnBars[b].flat().length; m++) { // b柱的號碼
              container.push([columnBars[a][l] , columnBars[b][m]]);
            }
          }

        } else {

          for (let c = b + 1 ; c < columnBars.length; c++) { // columnBars[b+1] 的柱子，命名為c柱
            
            if (star === 3){

              for (let l = 0; l < columnBars[a].flat().length; l++) { // a柱號碼
                for (let m = 0; m < columnBars[b].flat().length; m++) { // b柱號碼
                    for (let n = 0; n < columnBars[c].flat().length; n++) { //c柱號碼
                      container.push([columnBars[a][l], columnBars[b][m],columnBars[c][n]]);
                    }
                }
              }

            } else if (star === 4) {

              for (let d= c + 1 ; d < columnBars.length; d++) { // columnBars[c+1] 的柱子，命名為d柱
                for (let l = 0; l < columnBars[a].flat().length; l++) { // a柱號碼
                  for (let m = 0; m < columnBars[b].flat().length; m++) { // b柱號碼
                    for (let n = 0; n < columnBars[c].flat().length; n++) { // c柱號碼
                      for (let o = 0; o < columnBars[d].flat().length; o++) { // d柱號碼
                        container.push([columnBars[a][l], columnBars[b][m],columnBars[c][n],columnBars[d][o]]);
                      }
                    }
                  }
                }
              }

            }

          }      
          
        }
      }
    }

    if (selected.length > 0) {
      for (let z =0 ; z < selected.length ; z++){ //選號區
        for (let a = 0; a < columnBars.length ; a++) { //a柱

          if (star === 2) {

            for (let l = 0; l < columnBars[a].flat().length; l++) { //a柱的號碼
              container.push([selected[z], columnBars[a][l]]);
            }
            
          } else {
            for (let b = a + 1; b < columnBars.length; b++) { // columnBars[a+1] 的柱子，命名為b柱

              if (star === 3) {

                for (let l = 0; l < columnBars[a].flat().length; l++) { //a柱的號碼
                  for (let m = 0; m < columnBars[b].flat().length; m++) { // b柱的號碼
                    container.push([selected[z], columnBars[a][l],columnBars[b][m]]);
                  }
                }  

              } else if (star === 4){

                for (let c = b + 1 ; c < columnBars.length; c++) { // columnBars[b+1] 的柱子，命名為c柱
                  for (let l = 0; l < columnBars[a].flat().length; l++) { // a柱內的號碼
                    for (let m = 0; m < columnBars[b].flat().length; m++) { // b柱內的號碼
                      for (let n = 0; n < columnBars[c].flat().length; n++) { // c柱內的號碼
                        container.push([selected[z], columnBars[a][l],columnBars[b][m],columnBars[c][n]]);
                      }
                    }
                  }
                }

              }

            }
          }
            
        }
      }
    }

  }  else if (star === 2 & selected.length >= 2) {

    for(let i = 0 ; i < selected.length -1 ; i++){
      for(let j =i + 1 ; j < selected.length ; j++){
        container.push([selected[i],selected[j]]);
      }
    }
    
  } else if (star === 3 & selected.length >= 3) {

    for(let i = 0 ; i < selected.length -1 ; i++){
      for(let j =i + 1 ; j < selected.length ; j++){
        for(let k =j + 1 ; k < selected.length ; k++){
          container.push([selected[i],selected[j],selected[j]]);
        }
      }
    }

  } else if (star === 4 & selected.length >= 4) {
    for(let i = 0 ; i < selected.length -1 ; i++){
      for(let j =i + 1 ; j < selected.length ; j++){
        for(let k =j + 1 ; k < selected.length ; k++){
          for(let l =k + 1 ; l < selected.length ; l++){
            container.push([selected[i],selected[j],selected[j],,selected[l]]);
          }
        }
      }
    }
    
  }
  return container.length;

}

export const calculateNumberOfTainTwoThreeCombination = (ColumnsBar, selected , n) => {
  const specialColumn = ColumnsBar[0]; // 特別號的號碼
  let ans = 0
  let newColumnsBar = selected ? [...ColumnsBar, selected] : ColumnsBar;
  
  if (n === 2) {
      for (let i = 1; i < newColumnsBar.length; i++) {
          const count = specialColumn.flat().length * newColumnsBar[i].flat().length;
          ans += count;
      } 
  } else if (n === 3) {
      for (let i = 1; i < newColumnsBar.length; i++) {
          for (let m = i + 1 ; m < newColumnsBar.length; m++){
              const count = specialColumn.flat().length * newColumnsBar[i].flat().length * newColumnsBar[m].flat().length;
              ans += count;
          }
      } 
  }
  
  return ans;
}