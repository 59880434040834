import React, { useState , useEffect } from 'react';
import Swal from 'sweetalert2';
import liff from "@line/liff";
import ReactLoading from "react-loading";
import ColumnBallSelector from './components/ColumnBallSelector';
import ColumnResult from './components/ColumnResult';
import SubmitButton from './components/SubmitButton';
import { calculate234Cost } from '../utils';
// import StarColumnsOrderArea  from './components/StarBarOrderArea';
import MoneyPreview from './components/MoneyPreview';
import ColumnBarStarArea from './components/ColumnBarStarArea';
import IconImg from './components/IconImg';
import StarColumnsOrderArea  from './components/StarBarOrderArea';
import { useLocation } from 'react-router-dom';
import NumberSelectedArea from './components/NumberSelectedArea';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TwoThreeFour = (props) => {
  let query = useQuery();

  let balance = query.get("balance");
  let price = query.get("price");
  let odds = query.get("odds");
  let specific_odds = query.get("specific_odds");
  let betting_limit = query.get("betting_limit");
  let number_capped_betting = query.get("number_capped_betting");
  let column_capped_betting = query.get("column_capped_betting");

  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [columnBars, setColumnBar] = useState([]); //柱
  const [historySelectedNumbers, setHistorySelectedNumbers] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [multiplier, setMultiplier] = useState([0,0,0]); //注數
  const [branch, setBranch] = useState([0,0,0]); //支數
  const [loadingType, setLoadingType] = useState("blank");



  // const minLimit = parseFloat(betting_limit.split(',')[0]);
  // const maxLimit = parseFloat(betting_limit.split(',')[1]);
  const betting_split = betting_limit.split(',').reduce((acc, num, index) => {
    const subIndex = index % 3;
    if (!acc[subIndex]) {
      acc[subIndex] = [];
    }
    acc[subIndex].push(num);
    return acc;
  }, []);

  const minLimit = betting_split[0];
  const maxLimit = betting_split[1];


  useEffect(() => {
    setTotalAmount(calculate234Cost(branch, multiplier, price.split(",")));
  }, [selectedNumbers,columnBars, historySelectedNumbers ,multiplier, price.split(",")]);
  
  const handleSubmitClick = () => {

    if (totalAmount > balance) {
      Swal.fire({
        icon: 'error',
        title: `餘額不足, 您的餘額為 ${balance}`,
      })
      return
    } else if (totalAmount === 0){
      Swal.fire({
        icon: 'error',
        title: `下注金額不得為0`,
      })
      return
    }


    // 處理下注條件
    // map & for 不支援提前結束迴圈，因此需使用for...of

    
    for (const star of props.starList) {
      // 當有押注時，則至少 & 最多只能押多少
      if (multiplier[star-2] != 0) {
        if ((multiplier[star-2] < minLimit[star-2] || multiplier[star-2] > maxLimit[star-2])) {
          Swal.fire({
            icon: 'error',
            title: `${props.gameName === '二三四星' ? `${star}星`: props.gameName === '包牌' ? `${star}包`: `天${star}`}下注倍數不得小於 ${minLimit[star-2]} 或大於 ${maxLimit[star-2]}`,
          });
          return;
        }
      }
      
      
      // 有下注時，支柱必大於0
      if (multiplier[star-2] > 0 && branch[star-2] === 0){
        Swal.fire({
            icon: 'error',
            title: `${props.gameName === '二三四星' ? `${star}星`: props.gameName === '包牌' ? `${star}包`: `天${star}`}不得下注`,
          });
          return;
      }

    }
    
    const numbers_selected = [];
    columnBars.map((n) => numbers_selected.push(n.flat()));
    if (selectedNumbers){
      numbers_selected.push(selectedNumbers);
    }
    // 數字不滿2位的前面補0補滿兩位
    const numbers_with_comma = `${numbers_selected.map(arr => arr.map(num => num.toString().padStart(2, '0')).join('.')).join('/').replace(/\/$/, '')}`;
    // 僅回傳有下注的注數
    const multiplier_with_comma =`${props.starList.map((m, i) => multiplier[m-2] > 0 ? `${m}*${multiplier[m-2]}` : '').filter(str => str !== '').join(' ')}`
    console.log(`@${props.lottoType}-${props.gameName}\n${numbers_with_comma} ${multiplier_with_comma}`);

      
    try {
      liff.init({
        liffId: '2000056096-KxEexzbB',
      }).then(() => {
        if (!liff.isInClient()) {
          window.alert('This button is unavailable as LIFF is currently being opened in an external browser.');
          setLoadingType("blank");
        } else {
          liff.sendMessages([
            {
              type: 'text',
              text: `@${props.lottoType}-${props.gameName}\n${numbers_with_comma} ${multiplier_with_comma}`,
            },
          ])
            .then(() => {
              setLoadingType("blank");
              liff.closeWindow();
            })
            .catch((error) => {
              window.alert('Error sending message: ' + error);
            });
        }
      });
    } catch (error) {
      alert(error);
      setLoadingType("blank");
    }   
   
  };

  const numbers_selected = [];
  columnBars.map((n) => numbers_selected.push(n.flat()));
  if (selectedNumbers) {
    numbers_selected.push(selectedNumbers);
  }
  const numbers_with_comma = numbers_selected.map(subArray =>
    subArray.map(number => number.toString().padStart(2, '0')).join('.')
  ).join('/');
  

  return (
    <div className="app">
        <h1 className='title'><IconImg lottoType={props.lottoType}/>{props.lottoType}-{props.gameName}</h1>
      <ColumnBallSelector 
        selectedNumbers={selectedNumbers} 
        onSelect={setSelectedNumbers} 
        totalBallsCount={props.totalBalls} 
        ballNumberEachRow={10} 
        columnBars={columnBars}
        historySelectedNumbers={historySelectedNumbers} 
        setHistorySelectedNumbers={setHistorySelectedNumbers} 
        lottoType={props.lottoType}
        setSelectedNumbers={setSelectedNumbers} 
        setColumnBar={setColumnBar}
      />
      <NumberSelectedArea text={numbers_with_comma} />
      <ColumnBarStarArea 
        starList={props.starList}
        selectedNumbers={selectedNumbers} 
        columnBars={columnBars} 
        branch={branch}
        setBranch={setBranch}
        gameName={props.gameName}
      />

      <StarColumnsOrderArea 
        starList={props.starList}
        gameNameDispaly={props.gameName}
        multiplier={multiplier} 
        setMultiplier={setMultiplier} 
        price={price.split(",")}
        branch={branch}
        gameName={props.gameName}
        number_capped_betting={(props.gameName !== '天二天三' ? number_capped_betting.split(','): [])}
        column_capped_betting={(props.gameName !== '天二天三' ? number_capped_betting.split(','): [])}
        columnBars={columnBars}
        selectedNumbers={selectedNumbers}
      />
      <MoneyPreview         
        totalAmount={totalAmount}
        balance={balance}
      />
      <SubmitButton 
        onClick={handleSubmitClick} 
      />

      <div className={loadingType === "blank" ? "none-loading" : "loading-container"} >
        {loadingType === "blank" && <ReactLoading type="blank" />}
        {loadingType === "bubbles" && <ReactLoading type="bubbles" />}
      </div>
    </div>
  );
};

export default TwoThreeFour;