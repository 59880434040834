import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ColumnBarStarArea from './ColumnBarStarArea';

// todo: 要修改BallSelector, 讓Ball可以有參數控制是否可以被點擊取消選取, 立柱模式下不可以取消選取

const ColumnResult = ({ selectedNumbers, setSelectedNumbers, columnBars, setColumnBar, sethistorySelectedNumbers }) => {

  const handleColumnClick = () => {
    if (selectedNumbers.length > 0) {
      const faltColumnBars = columnBars.flat().flat();
      const newColumn = selectedNumbers.filter(element => !faltColumnBars.includes(element))
      setColumnBar([...columnBars, [newColumn]]);
      setSelectedNumbers([])
    }
  };

  const handleClearClick = () => {
    if (columnBars.length === 0 && selectedNumbers.length === 0) {
      return;
    }
    if (selectedNumbers.length > 0) {
      setSelectedNumbers(selectedNumbers.slice(0, -1));
      sethistorySelectedNumbers(selectedNumbers.slice(0, -1).concat(columnBars.flat()));
      return;
    }

    const lastColumn = columnBars[columnBars.length - 1].flat();
    if (lastColumn.length === 0) {
      return;
    }
    setColumnBar(columnBars.slice(0, -1));
    setSelectedNumbers(selectedNumbers.filter(element => !lastColumn.includes(element)))
    sethistorySelectedNumbers(columnBars.slice(0, -1));
  };

  // 一次清除立柱資料& 已選擇的號碼
  const handleClearAllClick = () => {
    setColumnBar([]);
    setSelectedNumbers([]);
    sethistorySelectedNumbers([]);
  };



  return (
    <div >
      <div className="column-action-botton">
        <button className="add-column-botton" onClick={handleColumnClick}>
          立柱
        </button>
        <button className="clear-column-botton" onClick={handleClearClick}>
          倒退
        </button>
        <button className="clear-all-column-botton" onClick={handleClearAllClick}>
          全清除
        </button>
      </div>
      {/* {render_columns_result(columnBars)} */}
      {/* <div className="column-group-container">
          {columnBars.map((column) => (    
              <div key={uuidv4()}
                  className="column-bar">
                  {column.map((number, index) => (
                  NumberDivs(index,number)
                ))}           
            </div>
        
          ))}
        </div> */}
    </div>
  );
};

export default ColumnResult;

function NumberDivs(index, number) {
  const divs = [];

  for (let i = 0; i < number.length; i++) {
    divs.push(
      <div key={uuidv4()} className="ball">
        {number[i]}
      </div>
    );
  }
  return <>{divs}</>;
}


const render_columns_result = (columnBars) => {
  const numbers_selected = [];
  columnBars.map((n) => numbers_selected.push(n.flat()));
  const numbers_with_comma = `${numbers_selected.map(arr => arr.join('.')).join('/').replace(/\/$/, '')}`
  console.log(numbers_with_comma)
  return (
    <div className='columns-reuslt-'>{numbers_with_comma}</div>
  )
};