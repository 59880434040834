import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import liff from "@line/liff";
import ReactLoading from "react-loading";
import BallSelector from './components/BallSelector';
import SubmitButton from './components/SubmitButton';
import ClearButton from './components/ClearButton';
import OrderArea from './components/OrderArea';
import { calculateCost } from '../utils';
import IconImg from './components/IconImg';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TianOne = (props) => {
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [historySelectedNumbers, setHistorySelectedNumbers] = useState([]);
  const [multiplier, setMultiplier] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loadingType, setLoadingType] = useState("blank");
  
  let query = useQuery();
  
  let balance = query.get("balance");
  let price = query.get("price");
  let odds = query.get("odds");
  let specific_odds = query.get("specific_odds");
  let betting_limit = query.get("betting_limit");

  const minLimit = parseFloat(betting_limit.split(',')[0]);
  const maxLimit = parseFloat(betting_limit.split(',')[1]);
  price = parseFloat(price.split(',')[0]);

  useEffect(() => {
    setTotalAmount(calculateCost(props.lottoType, props.gameName, selectedNumbers, multiplier, price));
  }, [selectedNumbers, multiplier]);


  
  const handleSubmit = () => {
    if (multiplier <= 0) {
      Swal.fire({
        icon: 'error',
        title: '下注請大於0',
      })
      return
    }

    if (totalAmount > balance) {
      Swal.fire({
        icon: 'error',
        title: `餘額不足, 您的餘額為 ${balance}`,
      })
      return
    }

    if (selectedNumbers.length == 0) {
      Swal.fire({
        icon: 'error',
        title: '請選擇號碼',
      })
      return
    }

    if (multiplier < minLimit && multiplier != 0) {
      Swal.fire({
        icon: 'error',
        title: `下注倍數不得小於 ${minLimit}`,
      })
      return
    }

    if (multiplier > maxLimit) {
      Swal.fire({
        icon: 'error',
        title: `下注倍數不得大於 ${maxLimit}`,
      })
      return
    }
    
    setLoadingType("bubbles");
    const numbers_with_comma = selectedNumbers.map((n) => String(n).padStart(2, '0')).join(".");
    // console.log(`@${props.lottoType}-${props.gameName}\n${numbers_with_comma} ${multiplier}`);
    try {
      liff.init({
        liffId: '2000056096-KxEexzbB',
      }).then(() => {
        if (!liff.isInClient()) {
          window.alert('This button is unavailable as LIFF is currently being opened in an external browser.');
          setLoadingType("blank");
        } else {
          liff.sendMessages([
            {
              type: 'text',
              text: `@${props.lottoType}-${props.gameName}\n${numbers_with_comma} ${multiplier}`,
            },
          ])
            .then(() => {
              setLoadingType("blank");
              liff.closeWindow();
            })
            .catch((error) => {
              window.alert('Error sending message: ' + error);
            });
        }
      });
    } catch (error) {
      alert(error);
      setLoadingType("blank");
    }
  
  };

  return (
    <div className="app">
      <h1 className='title'><IconImg lottoType={props.lottoType} />{props.lottoType}-{props.gameName}</h1>
      <BallSelector
        selectedNumbers={selectedNumbers}
        onSelect={setSelectedNumbers}
        historySelectedNumbers={historySelectedNumbers}
        setHistorySelectedNumbers={setHistorySelectedNumbers}
        totalBallsCount={(props.lottoType === '今彩539' ? 39 : 49)}
        ballNumberEachRow={10}
        lottoType={props.lottoType}
      />
      <OrderArea
        gameNameDispaly={props.gameName}
        multiplier={multiplier}
        setMultiplier={setMultiplier}
        unitPrice={Math.round(price)}
        totalAmount={totalAmount}
        balance={balance}
      />
      {/* <MoneyPreview totalAmount={totalAmount} /> */}
      <div className={loadingType === "blank" ? "none-loading" : "loading-container"} >
        {loadingType === "blank" && <ReactLoading type="blank" />}
        {loadingType === "bubbles" && <ReactLoading type="bubbles" />}
      </div>
      <div className='button-container'>
        <SubmitButton onClick={handleSubmit} />
        <ClearButton numbers={selectedNumbers} onClick={setSelectedNumbers} />
      </div>
    </div>
  );
};

export default TianOne;