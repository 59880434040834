import Ball from './Ball';
import { v4 as uuidv4 } from 'uuid';
import IconBall from './IconBall';
import NumberSelectedArea from './NumberSelectedArea';
import ColumnResult from './ColumnResult';

const ColumnBallSelector = ({ selectedNumbers, onSelect, totalBallsCount, ballNumberEachRow, columnBars, historySelectedNumbers, setHistorySelectedNumbers, lottoType, setSelectedNumbers, setColumnBar }) => {


  const handleBallClick = (number) => {

    if (selectedNumbers.includes(number)) {
      if (columnBars.length === 0) {
        setHistorySelectedNumbers(selectedNumbers.filter((n) => n !== number));
        onSelect(selectedNumbers.filter((n) => n !== number));
      } else {
        onSelect(selectedNumbers.filter((n) => n !== number));
        setHistorySelectedNumbers(selectedNumbers.filter((n) => n !== number).concat(columnBars.flat().flat()));
      }
    };

    if (!selectedNumbers.includes(number) & !columnBars.flat().flat().includes(number)) {
      setHistorySelectedNumbers([...historySelectedNumbers, number]);
      onSelect([...selectedNumbers, number]);
    };

  };

  const rows = [];
  let row = [];
  for (let i = 0; i <= totalBallsCount; i++) {
    if (i === 0) {
      row.push(
        <IconBall
          key={uuidv4()}
          lottoType={lottoType}
        />
      )
    } else {
      row.push(
        <Ball
          key={uuidv4()}
          number={i}
          selected={historySelectedNumbers.flat().flat().includes(i)}
          onClick={handleBallClick}
        />
      );
    }
    if (i % ballNumberEachRow === 9) {
      rows.push(
        <div key={uuidv4()} className="ball-row">
          {row}
        </div>
      );
      row = [];
    } else if (i === totalBallsCount && row.length < ballNumberEachRow) {
      rows.push(
        <div key={uuidv4()} className="ball-row">
          {row}
        </div>
      );
    }
  }

  return (
    <div>
      {render_selected_result(columnBars, selectedNumbers, setSelectedNumbers, setColumnBar, setHistorySelectedNumbers)}
      <div className="ball-selector">
        <div className="ball-container">
          {rows}
        </div>
      </div>
    </div>
  );
};

export default ColumnBallSelector;


const render_selected_result = (columnBars, selectedNumbers, setSelectedNumbers, setColumnBar, setHistorySelectedNumbers) => {
  // const numbers_selected = [];
  // columnBars.map((n) => numbers_selected.push(n.flat()));
  // if (selectedNumbers) {
  //   numbers_selected.push(selectedNumbers);
  // }
  // const numbers_with_comma = `${numbers_selected.map(arr => arr.join(',')).join(' X ')}`
  return (
    <div>
      {/* <NumberSelectedArea text={numbers_with_comma} /> */}
    <ColumnResult 
          selectedNumbers={selectedNumbers} 
          columnBars={columnBars} 
          setSelectedNumbers={setSelectedNumbers} 
          setColumnBar={setColumnBar} 
          sethistorySelectedNumbers={setHistorySelectedNumbers}  
        />
    </div>
    // <div className='selected-reuslt'>{numbers_with_comma}</div>
  )
};