import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Car from './game/Car';
import TwoThreeFour from './game/TwoThreeFour';
import TaiSpecial from './game/TaiSpecial';
import Tai from './game/Tai';
import TianOne from './game/TianOne';
import Tail from './game/Tail';
import liff from '@line/liff';
import NotFound from './game/components/NotFound';

const gameConfig = {
  "BigCar": {
    "lottoType": "大樂透",
    "gameName": "全車",
    "unitPrice": 72.8,
    "totalBalls": 49,
  },  
  "FiveCar": {
    "lottoType": "今彩539",
    "gameName": "全車",
    "unitPrice": 71.8,
    "totalBalls": 39 ,
  },
  "SixCar": {
    "lottoType": "六合彩",
    "gameName": "全車",
    "unitPrice": 72.8,
    "totalBalls": 49 ,
  },
  "Big234": {
    "lottoType": "大樂透",
    "gameName": "二三四星",
    "totalBalls": 49 ,
    "starList":[2,3,4]
  },
  "Six234": {
    "lottoType": "六合彩",
    "gameName": "二三四星",
    "totalBalls": 49 ,
    "starList":[2,3,4]
  },
  "Five234": {
    "lottoType": "今彩539",
    "gameName": "二三四星",
    "totalBalls": 39 ,
    "starList":[2,3,4]
  },
  "BigPackageBet": {
    "lottoType": "大樂透",
    "gameName": "包牌",
    "totalBalls": 49 ,
    "starList":[2,3,4]
  },
  "SixPackageBet": {
    "lottoType": "六合彩",
    "gameName": "包牌",
    "totalBalls": 49 ,
    "starList":[2,3,4]
  },
  "FivePackageBet": {
    "lottoType": "今彩539",
    "gameName": "包牌",
    "totalBalls": 39 ,
    "starList":[2,3,4]
  },
  "BigTaiSpecial": {
    "lottoType": "大樂透",
    "gameName": "台特",
    "unitPrice": 60,
    "numberCount": 3
  },
  "SixTaiSpecial": {
    "lottoType": "六合彩",
    "gameName": "台特",
    "unitPrice": 60,
    "numberCount": 3
  },
  "FiveTaiSpecial": {
    "lottoType": "今彩539",
    "gameName": "台特",
    "unitPrice": 60,
    "numberCount": 3
  },  
  "BigTai": {
    "lottoType": "大樂透",
    "gameName": "台號",
    "unitPrice": 76,
    "numberCount": 2
  }, 
  "SixTai": {
    "lottoType": "六合彩",
    "gameName": "台號",
    "unitPrice": 76,
    "numberCount": 2
  }, 
  "FiveTai": {
    "lottoType": "今彩539",
    "gameName": "台號",
    "unitPrice": 76,
    "numberCount": 2
  }, 
  "BigTianOne": {
    "lottoType": "大樂透",
    "gameName": "天一",
    "unitPrice": 74,
    "buyAll":[ 76, 126, 211]
  },
  "SixTianOne": {
    "lottoType": "六合彩",
    "gameName": "天一",
    "unitPrice": 74,
    "buyAll":[ 76, 126, 211]
  },
  "BigTainTwoThree": {
    "lottoType": "大樂透",
    "gameName": "天二天三",
    "totalBalls": 49 ,
    "starList":[2,3]
  },
  "SixTainTwoThree": {
    "lottoType": "六合彩",
    "gameName": "天二天三",
    "totalBalls": 49 ,
    "starList":[2,3]
  },
  "BigTail": {
    "lottoType": "大樂透",
    "gameName": "尾數",
    "unitPrice": 81,
    "totalBalls": 9,
  },
  "SixTail": {
    "lottoType": "六合彩",
    "gameName": "尾數",
    "unitPrice": 81,
    "totalBalls": 9,
  },
  "FiveTail": {
    "lottoType": "今彩539",
    "gameName": "尾數",
    "unitPrice": 81,
    "totalBalls": 9,
  },
 
}

const router = createBrowserRouter([
  {
    path: "/big_car",
    element: <Car gameName={gameConfig.BigCar.gameName} lottoType={gameConfig.BigCar.lottoType} totalBalls={gameConfig.BigCar.totalBalls}/>,
  },  
  {
    path: "/five_car",
    element: <Car gameName={gameConfig.FiveCar.gameName} lottoType={gameConfig.FiveCar.lottoType} unitPrice={gameConfig.FiveCar.unitPrice} totalBalls={gameConfig.FiveCar.totalBalls}/>,
  },
  {
    path: "/six_car",
    element: <Car gameName={gameConfig.SixCar.gameName} lottoType={gameConfig.SixCar.lottoType} unitPrice={gameConfig.SixCar.unitPrice} totalBalls={gameConfig.BigCar.totalBalls}/>,
  },
  {
    // /big_234/:balance/:price/:odds/:betting_limit/:number_capped_betting/:column_capped_betting
    // example path 
    path: "/big_234",
    element: <TwoThreeFour gameName={gameConfig.Big234.gameName} lottoType={gameConfig.Big234.lottoType} totalBalls={gameConfig.Big234.totalBalls} starList={gameConfig.Big234.starList}/>,
  },
  {
    path: "/six_234",
    element: <TwoThreeFour gameName={gameConfig.Six234.gameName} lottoType={gameConfig.Six234.lottoType} totalBalls={gameConfig.Six234.totalBalls} starList={gameConfig.Six234.starList}/>,
  },
  {
    path: "/five_234",
    element: <TwoThreeFour gameName={gameConfig.Five234.gameName} lottoType={gameConfig.Five234.lottoType} totalBalls={gameConfig.Five234.totalBalls} starList={gameConfig.Five234.starList}/>,
  },
  {
    // /:balance/:price/:odds/:betting_limit/:number_capped_betting/:column_capped_betting
    path: "/big_package_bet",
    element: <TwoThreeFour gameName={gameConfig.BigPackageBet.gameName} lottoType={gameConfig.BigPackageBet.lottoType} totalBalls={gameConfig.BigPackageBet.totalBalls} starList={gameConfig.BigPackageBet.starList}/>,
  },
  {
    path: "/six_package_bet",
    element: <TwoThreeFour gameName={gameConfig.SixPackageBet.gameName} lottoType={gameConfig.SixPackageBet.lottoType} totalBalls={gameConfig.SixPackageBet.totalBalls} starList={gameConfig.SixPackageBet.starList}/>,
  },
  {
    path: "/five_package_bet",
    element: <TwoThreeFour gameName={gameConfig.FivePackageBet.gameName} lottoType={gameConfig.FivePackageBet.lottoType} totalBalls={gameConfig.FivePackageBet.totalBalls} starList={gameConfig.FivePackageBet.starList}/>,
  },
  {
    path: "/big_tai_special",
    element: <TaiSpecial gameName={gameConfig.BigTaiSpecial.gameName} lottoType={gameConfig.BigTaiSpecial.lottoType} numberCount={gameConfig.BigTaiSpecial.numberCount}/>,
  },
  {
    path: "/six_tai_special",
    element: <TaiSpecial gameName={gameConfig.SixTaiSpecial.gameName} lottoType={gameConfig.SixTaiSpecial.lottoType} numberCount={gameConfig.SixTaiSpecial.numberCount}/>,
  },
  {
    path: "/five_tai_special",
    element: <TaiSpecial gameName={gameConfig.FiveTaiSpecial.gameName} lottoType={gameConfig.FiveTaiSpecial.lottoType} numberCount={gameConfig.FiveTaiSpecial.numberCount}/>,
  },
  {
    path: "/big_tai",
    element: <Tai gameName={gameConfig.BigTai.gameName} lottoType={gameConfig.BigTai.lottoType} numberCount={gameConfig.BigTai.numberCount}/>,
  },  
  {
    path: "/six_tai",
    element: <Tai gameName={gameConfig.SixTai.gameName} lottoType={gameConfig.SixTai.lottoType} numberCount={gameConfig.SixTai.numberCount}/>,
  },
  {
    path: "/five_tai",
    element: <Tai gameName={gameConfig.FiveTai.gameName} lottoType={gameConfig.FiveTai.lottoType} numberCount={gameConfig.FiveTai.numberCount}/>,
  },   
  {
    path: "/big_tian_one",
    element: <TianOne gameName={gameConfig.BigTianOne.gameName} lottoType={gameConfig.BigTianOne.lottoType} unitPrice={gameConfig.BigTianOne.unitPrice} />,
  },
  {
    path: "/six_tian_one",
    element: <TianOne gameName={gameConfig.SixTianOne.gameName} lottoType={gameConfig.SixTianOne.lottoType} unitPrice={gameConfig.SixTianOne.unitPrice} />,
  },
  // {
  //   path: "/big_tail/:balance/:price/:odds/:specific_odds/:betting_limit",
  //   element: <Tail gameName={gameConfig.BigTail.gameName} lottoType={gameConfig.BigTail.lottoType} totalBalls={gameConfig.BigTail.totalBalls}/>,
  // },
  // {
  //   path: "/six_tail/:balance/:price/:odds/:specific_odds/:betting_limit",
  //   element: <Tail gameName={gameConfig.SixTail.gameName} lottoType={gameConfig.SixTail.lottoType} totalBalls={gameConfig.SixTail.totalBalls}/>,
  // },
  // {
  //   path: "/five_tail/:balance/:price/:odds/:specific_odds/:betting_limit",
  //   element: <Tail gameName={gameConfig.FiveTail.gameName} lottoType={gameConfig.FiveTail.lottoType} totalBalls={gameConfig.FiveTail.totalBalls}/>,
  // },
  {
    path: "/big_tainTwoThree",
    element: <TwoThreeFour gameName={gameConfig.BigTainTwoThree.gameName} lottoType={gameConfig.BigTainTwoThree.lottoType} totalBalls={gameConfig.BigTainTwoThree.totalBalls} starList={gameConfig.BigTainTwoThree.starList}/>,
  },
  {
    path: "/six_tainTwoThree",
    element: <TwoThreeFour gameName={gameConfig.SixTainTwoThree.gameName} lottoType={gameConfig.SixTainTwoThree.lottoType} totalBalls={gameConfig.SixTainTwoThree.totalBalls} starList={gameConfig.SixTainTwoThree.starList}/>,
  },
  {
    path: "*",
    element: <NotFound />,
  }
]);

const App = () => {

  useEffect(() => {
    liff.init({ liffId: '2000056096-KxEexzbB' })
    });


  return (
    <RouterProvider router={router} />
  );
};

export default App;