import React, { useState, useEffect } from 'react';
import { calculateNumberOfStarCombination , calculateNumberOfTainTwoThreeCombination} from '../../utils';

const ColumnBarStarArea = ({ starList , selectedNumbers, columnBars , branch , setBranch , gameName}) => {
  const [starSelected, setStarSelected] = useState(selectedNumbers);
  const [starColumns, setStarColumns] = useState(columnBars);

  useEffect(() => {
    setStarSelected(selectedNumbers);
    setStarColumns(columnBars);
  }, [selectedNumbers, columnBars]);

  return <div className='order-area'>
    <div className='order-area-row'>
      {starList.map((star) => {
        if (gameName ==='天二天三') {
          return <TainTwoThreeResult key={star} star={star} starSelected={starSelected} starColumns={starColumns} branch={branch} setBranch={setBranch}/>
        } else {
          return <NormalResult key={star} star={star} starSelected={starSelected} starColumns={starColumns} branch={branch} setBranch={setBranch} gameName={gameName}/>
        }
      })}
    </div>
  </div>;
};



const NormalResult = ({ star, starColumns, starSelected ,branch , setBranch , gameName}) => {

  const count = starColumns.length === 0 ? (starSelected.length >= star ? starSelected.length : 0) : (starSelected.length > 0 ? starColumns.length + 1 : starColumns.length); 

  useEffect(()=>{

    setBranch([
      calculateNumberOfStarCombination(starColumns,starSelected,2),
      calculateNumberOfStarCombination(starColumns,starSelected,3),
      calculateNumberOfStarCombination(starColumns,starSelected,4)
    ]);
  },[starSelected,starColumns])

  

  return (
    <div className='order-input'>
      <p>
      {star} {gameName ==='包牌' ? '包' : '星'}:
      {starColumns.length === 0 ? ' 連碰' : ' 立柱'}
      {count}
      {starColumns.length === 0 ? ' 號 ' : ' 柱 '} 
      {branch[star - 2]} 支
      </p>
    </div>
  );
};


const TainTwoThreeResult = ({ star, starColumns, starSelected ,branch , setBranch}) => {

  const count = starColumns.length === 0 ? 0 : (starSelected.length + starColumns.slice(1).flat().flat().length);
  useEffect(()=>{
    setBranch([calculateNumberOfTainTwoThreeCombination(starColumns,starSelected,2),
      calculateNumberOfTainTwoThreeCombination(starColumns,starSelected,3),
      calculateNumberOfTainTwoThreeCombination(starColumns,starSelected,4)])
  },[starSelected,starColumns])

  return (
    <div className='order-input'>
      <p>
      天{star}:{branch[star - 2]} 支
      特別號:{starColumns.length === 0 ? starSelected.length : starColumns[0].flat().length}球 選號:{count}球
      </p>
    </div>
  );
};

export default ColumnBarStarArea;