import React from 'react';

const MoneyPreview = ({ totalAmount , balance}) => {


  return (
    <div className="money-preview">
      <p>您的餘額為 {balance} 元。</p>
      <p>總共需支付 {totalAmount} 元。</p>
    </div>
  );
  
  
};

export default MoneyPreview;
