import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import liff from "@line/liff";
import ThreeBallsSelector from './components/ThreeBallsSelector'
import OrderArea from './components/OrderArea';
import SubmitButton from './components/SubmitButton';
import ReactLoading from "react-loading";
import { calculateTaiSpecialCost } from '../utils';
import IconImg from './components/IconImg';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Tai = (props) => {
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [multiplier, setMultiplier] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loadingType, setLoadingType] = useState("blank");
  const [historySelectedNumbers, setHistorySelectedNumbers] = useState([]);

  let query = useQuery();
  
  let balance = query.get("balance");
  let price = query.get("price");
  let odds = query.get("odds");
  let specific_odds = query.get("specific_odds");
  let betting_limit = query.get("betting_limit");




  const minLimit = parseFloat(betting_limit.split(',')[0]);
  const maxLimit = parseFloat(betting_limit.split(',')[1]);
  price = parseFloat(price.split(',')[0]);

  useEffect(() => {
    setTotalAmount(calculateTaiSpecialCost(selectedNumbers, multiplier, price));
  }, [selectedNumbers, multiplier]);

  const handleSubmit = () => {

    if (multiplier <= 0) {
      Swal.fire({
        icon: 'error',
        title: '下注請大於0',
      })
      return
    }

    if (selectedNumbers.length == 0) {
      Swal.fire({
        icon: 'error',
        title: '請選擇號碼',
      })
      return
    }

    if (totalAmount > balance) {
      Swal.fire({
        icon: 'error',
        title: `餘額不足, 您的餘額為 ${balance}`,
      })
      return
    }

    if (multiplier < minLimit) {
      Swal.fire({
        icon: 'error',
        title: `下注倍數不得小於 ${minLimit}`,
      })
      return
    }

    if (multiplier > maxLimit) {
      Swal.fire({
        icon: 'error',
        title: `下注倍數不得大於 ${maxLimit}`,
      })
      return
    }

    setLoadingType("bubbles");
    const numbers_with_comma = selectedNumbers.map((n) => String(n).padStart(2, '0')).join(".");
    if (selectedNumbers.length > 0) {
      // if (result.isConfirmed) {
        // Swal.fire({
        //   icon: 'success',
        //   title: '提交成功'
        // }).then(() => {
          try {
            liff.init({
              liffId: '2000056096-KxEexzbB',
            }).then(() => {
              if (!liff.isInClient()) {
                window.alert('This button is unavailable as LIFF is currently being opened in an external browser.');
                setLoadingType("blank");
              } else {
                liff.sendMessages([
                  {
                    type: 'text',
                    text: `@${props.lottoType}-${props.gameName}\n${numbers_with_comma} ${multiplier}`,
                  },
                ])
                  .then(() => {
                    setLoadingType("blank");
                    liff.closeWindow();
                  })
                  .catch((error) => {
                    window.alert('Error sending message: ' + error);
                  });
              }
            });
          } catch (error) {
            alert(error);
            setLoadingType("blank");
          }
        // })
      // Swal.fire({
      //   icon: 'warning',
      //   title: '確認您的訂單',
      //   html: `您選擇的號碼為:<br/>${numbers_with_comma}<br/>共計 ${totalAmount} 元<br/>是否確認提交?`,
      //   showCancelButton: true,
      //   confirmButtonColor: '#d84315',
      //   confirmButtonText: '確認提交',
      //   cancelButtonText: '返回',
      //   cancelButtonColor: '#6c757d'
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     Swal.fire({
      //       icon: 'success',
      //       title: '提交成功'
      //     }).then(() => {
      //       try {
      //         liff.init({
      //           liffId: '2000056096-KxEexzbB',
      //         }).then(() => {
      //           if (!liff.isInClient()) {
      //             window.alert('This button is unavailable as LIFF is currently being opened in an external browser.');
      //             setLoadingType("blank");
      //           } else {
      //             liff.sendMessages([
      //               {
      //                 type: 'text',
      //                 text: `${numbers_with_comma} ${multiplier}`,
      //               },
      //             ])
      //               .then(() => {
      //                 setLoadingType("blank");
      //                 window.close();
      //               })
      //               .catch((error) => {
      //                 window.alert('Error sending message: ' + error);
      //               });
      //           }
      //         });
      //       } catch (error) {
      //         alert(error);
      //         setLoadingType("blank");
      //       }
      //     })
      //   }
      // });
    } else {
      Swal.fire({
        icon: 'error',
        title: '請選擇號碼',
      })
    }
  };

  console.log(props.numberCount)
  return (
    <div className="app">
      <h1 className='title'><IconImg lottoType={props.lottoType} />{props.lottoType}-{props.gameName}</h1>
      <ThreeBallsSelector
        selectedNumbers={selectedNumbers}
        setSelectedNumbers={setSelectedNumbers}
        numberCount={props.numberCount}
        gameName={props.gameName}
        historySelectedNumbers={historySelectedNumbers}
        setHistorySelectedNumbers={setHistorySelectedNumbers}
      />
      <OrderArea
        gameNameDispaly={props.gameName}
        multiplier={multiplier}
        setMultiplier={setMultiplier}
        unitPrice={Math.round(price)}
        totalAmount={totalAmount}
        balance={balance}
      />

      <div className={loadingType === "blank" ? "none-loading" : "loading-container"} >
        {loadingType === "blank" && <ReactLoading type="blank" />}
        {loadingType === "bubbles" && <ReactLoading type="bubbles" />}
      </div>
      <SubmitButton onClick={handleSubmit} />
    </div>
  )
}


export default Tai;