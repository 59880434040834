import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div style={{textAlign: "center"}}>
            <h1>跳轉中...</h1>
            <progress>1</progress>
        </div>
    )
}

export default NotFound