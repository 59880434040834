import React, { useState ,useEffect} from 'react';
import Swal from 'sweetalert2';

const ThreeBallsSelector = ({selectedNumbers, setSelectedNumbers , numberCount , gameName , historySelectedNumbers , setHistorySelectedNumbers}) => {
  const [numbers, setNumbers] = useState(Array(numberCount).fill(''));


  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      const inputs = document.querySelectorAll('.input-three-ball-area input');
      inputs.forEach((input) => {
        input.setAttribute('type', 'tel');
        input.setAttribute('pattern', '[0-9]*');
        input.setAttribute('inputMode', 'numeric');
      });

     
    }
  }, []);



  const handleNumberChange = (index, event) => {
    let newNumbers = [...numbers];
    let inputVal = event.target.value.substring(0, 1);

    if (!isNaN(inputVal) && inputVal !== '') {
      newNumbers[index] = inputVal;

      setNumbers(newNumbers);

      if (index < numberCount - 1 && newNumbers[index].length === 1) {
        const nextInput = event.target.nextElementSibling;
        if (nextInput) {
          nextInput.focus();
          nextInput.select();
        }
      }
    }
  };

  const threeBallsAddClick = () => {

    if (numbers.every((number) => number !== '')) {
      const newNumber = numbers.join('');
      if (selectedNumbers.includes(newNumber)) {
        Swal.fire({
          icon: 'error',
          title: `${newNumber}${gameName === '尾號' ? '尾' : ''} 已選擇`,
        });
      } else {
        setSelectedNumbers([...selectedNumbers, numbers.join('')]);
        setNumbers(Array(numberCount).fill(''));
        setHistorySelectedNumbers([...historySelectedNumbers, selectedNumbers]);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: '請填入所有選號區欄位',
      });
    }

  };

  // const threeBallsClearClick = () => {
  //   setNumbers(Array(numberCount).fill('0'));
  //   setHistorySelectedNumbers([...historySelectedNumbers, selectedNumbers]);
  //   setSelectedNumbers([]);
  // };

  // const handleSelectedNumberClearClick = (number) => {
  //   const newSelectedNumbers = selectedNumbers.filter((n) => n !== number);
  //   setSelectedNumbers(newSelectedNumbers);
  //   setHistorySelectedNumbers([...historySelectedNumbers, selectedNumbers]);
  // };

  const handleHistoryClick = () => {
    if (historySelectedNumbers.length > 0) {
      const lastState = historySelectedNumbers[historySelectedNumbers.length - 1];
      setSelectedNumbers(lastState);
      setHistorySelectedNumbers(historySelectedNumbers.slice(0, -1));
    }
  };




  return (
    <div className="three-ball-selector">
      <h2>輸入後送出即可加入選號區:</h2>
      <div className="input-three-ball-area">
        {numbers.map((number, index) => (
          <input
            key={index}
            type="text"
            min="0"
            max="9"
            value={number}
            onChange={(event) => handleNumberChange(index, event)}
            onFocus={(event) => {
              event.target.setSelectionRange(0, event.target.value.length);
            }}
          />
        ))}
      </div>
      <div className="three-ball-buttons">
        <button className="three-ball-selector-btn add" onClick={threeBallsAddClick}>
          加入
        </button>
        <button className="three-ball-selector-btn clear" onClick={handleHistoryClick}>
          回復
        </button>
      </div>

      <div className="three-ball-selected-area">
        <h2>已選號碼</h2>
        <div className='selected-reuslt'>{selectedNumbers.join('.')}</div>
      </div>
    </div>
  );
};

export default ThreeBallsSelector;