import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import Swal from 'sweetalert2';

  
const StarColumnsOrderArea = ({ starList , multiplier, setMultiplier ,price , branch  , gameName , number_capped_betting , column_capped_betting , columnBars , selectedNumbers }) => {
  


  const initMultiplier = (star_list) => {
    const updatedMultiplier = [...multiplier];
    star_list.forEach((star) => {
      // console.log(`重製${star} 當前下注${updatedMultiplier}`)
      updatedMultiplier[star - 2] = 0;
      // console.log(`重製${star} 更新後下注${updatedMultiplier}`)
    })
    setMultiplier(updatedMultiplier);
    
  };


  const showAlert = (alarmText) => {
    Swal.fire({
      icon:'info',
      title:'提示!!',
      text:alarmText,
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };
  
  useEffect(() => {
    if (gameName !== '天二天三'){
      let alarmText = '';
      let initStarMultiplier = [];
      starList.forEach((star) => {
        if (gameName !== '包牌' && multiplier[star - 2] > 0 && (columnBars.length === 0 ? branch[star - 2] >= number_capped_betting[star - 2] :  branch[star - 2] >= column_capped_betting[star - 2])) {
          alarmText += (alarmText ? '、' : '') + `${star}星`;
          initStarMultiplier.push(star);
        } else if (gameName === '包牌' && multiplier[star - 2] > 0 && (columnBars.length === 0 ? branch[star - 2] < number_capped_betting[star - 2] : branch[star - 2] < column_capped_betting[star - 2])) {
          alarmText += (alarmText ? '、' : '') + `${star}包`;
          initStarMultiplier.push(star);
        }
      });
  
      if (alarmText) {
        const isPackageGame = gameName === '包牌';
        const conditionText = columnBars.length === 0 ? (isPackageGame ? '連號未達包牌條件，非包牌請至一般遊戲區下注' : '連號已達包牌條件，若預包牌請至包牌遊戲區下注') : (isPackageGame ? '立柱未達包牌條件，若預包牌請至一般遊戲區下注' : '立柱已達包牌條件，若預包牌請至包牌遊戲區下注');
        showAlert(`${alarmText}${conditionText}`);
        initMultiplier(initStarMultiplier);
      }
    }

    // 當支數小於時，需將下注改為0
    starList.forEach((star) => {
      if (branch[star - 2] <= 0){        
        const updatedMultiplier = multiplier;
        updatedMultiplier[star - 2] = 0;
        setMultiplier(updatedMultiplier);
      }
    })
    

  }, [branch]);
  
  
  return <div className="order-area">
    <div className="order-area-row">
    {starList.map((star) => {
      if (gameName ==='天二天三') {
        return  <TainTwoThreeStarColumnsOrder
                  key={star}
                  star={star}
                  multiplier={multiplier}
                  setMultiplier={setMultiplier}
                  price={price}
                  branch={branch}
                />
      } else {       
        return  <NormalStarColumnsOrder
                  key={star}
                  star={star}
                  multiplier={multiplier}
                  setMultiplier={setMultiplier}
                  price={price}
                  branch={branch}
                  number_capped_betting={number_capped_betting}
                  column_capped_betting={column_capped_betting}
                  gameName = {gameName}
                  columnBars={columnBars}
                />
      } 
    })}
    </div>
  </div>;
  
  }
  
  StarColumnsOrderArea.propTypes = {
    gameNameDispaly: PropTypes.string.isRequired,
};

  
export default StarColumnsOrderArea;

const NormalStarColumnsOrder = ({ star, multiplier, setMultiplier ,price , branch  , number_capped_betting , column_capped_betting, gameName , columnBars }) => {
  
  const isBettingConditionMet =
    (gameName === '包牌' && (columnBars.length === 0 ? branch[star - 2] >= number_capped_betting[star - 2] : branch[star - 2] >= column_capped_betting[star - 2])) ||
    (gameName !== '包牌' && branch[star - 2] > 0 && (columnBars.length === 0 ? branch[star - 2] < number_capped_betting[star - 2] : branch[star - 2] < column_capped_betting[star - 2]));

  
    // const handleMultiplierChange = (e) => {
    //   const floatValue = parseFloat(e.target.value);
    //   const updatedMultiplier = [...multiplier];
    //   if (!isNaN(floatValue)) {
    //     updatedMultiplier[star - 2] = floatValue;
    //     setMultiplier(updatedMultiplier);
    //   } else if (e.target.value === "") {
    //     updatedMultiplier[star - 2] = "";
    //     setMultiplier(updatedMultiplier);
    //   }
    // };
  
    // const handleMultiplierBlur = (e) => {
    //   if (!e.target.value || isNaN(parseFloat(e.target.value))) {
    //     const updatedMultiplier = [...multiplier];
    //     updatedMultiplier[star - 2] = 0;
    //     setMultiplier(updatedMultiplier);
    //   }
    // };


  return (
    <>
      {isBettingConditionMet ? (
        <div>
          <div className="order-input">
            <span>{star} {gameName === '包牌' ? '包' : '星'} ×</span>
            <input
              type="number"
              inputMode='decimal'
              step={0.1}
              min="0"
              onFocus={(e) => e.target.select()}
              value={multiplier[star - 2]}
              onChange={(e) => {
                const floatValue = parseFloat(e.target.value);
                const updatedMultiplier = [...multiplier];
                if (!isNaN(floatValue)) {
                  updatedMultiplier[star-2] = floatValue;
                  setMultiplier(updatedMultiplier);
                } else if (e.target.value === "") {
                  updatedMultiplier[star-2] = "";
                  setMultiplier(updatedMultiplier);
                }  
              }}
              onBlur={(e) => {
                if (!e.target.value || isNaN(parseFloat(e.target.value))) {
                  const updatedMultiplier = [...multiplier];
                  updatedMultiplier[star-2] = 0;
                  setMultiplier(updatedMultiplier);
                }
              }}
            />
            <span>1={price[star - 2]}元</span>
          </div>
        </div>
      ) : (
        <div className='order-area-row-not-inpt' >
          <span >{star} {gameName === '包牌' ? '包' : '星'}
          {columnBars.length === 0 ? '連碰' : '立柱'}
          {gameName === '包牌' ? `未達包牌(${column_capped_betting[star - 2]}支)條件`: branch[star-2] > 0 ? `已達包牌(${column_capped_betting[star - 2]}支)條件` :"未達下注條件"}   
          {/* {branch[star-2] > 0 ? gameName === '包牌'? '，請至一般區下注' : '，請至包牌區下注' :''} */}
          </span>
        </div>
        
      )}
    </>
  );
};



const TainTwoThreeStarColumnsOrder = ({ star, multiplier, setMultiplier ,price , branch }) => {

  const isBettingConditionMet = branch[star-2] > 0;

  return (
    <>
      { isBettingConditionMet ? (
        <div className="order-input">
          <span>天{star} ×</span>
          <input 
            type="number" 
            inputMode='decimal' 
            step={0.1}
            min="0" 
            onFocus={(e) => e.target.select()}
            value={multiplier[star-2]} 
            onChange={(e) => {
              const floatValue = parseFloat(e.target.value);
              const updatedMultiplier = [...multiplier];
              if (!isNaN(floatValue)) {
                updatedMultiplier[star-2] = floatValue;
                setMultiplier(updatedMultiplier);
              } else if (e.target.value === "") {
                updatedMultiplier[star-2] = "";
                setMultiplier(updatedMultiplier);
              }         
            }}  
            onBlur={(e) => {
              if (!e.target.value || isNaN(parseFloat(e.target.value))) {
                const updatedMultiplier = [...multiplier];
                updatedMultiplier[star-2] = 0;
                setMultiplier(updatedMultiplier);
              }
            }}
          />
          <span>1={price[star-2]}元</span>
        </div>
       ) : (
        <div className='order-area-row-not-inpt' >
          <span >天{star}未達下注條件
          </span>
        </div>
       )

      }
      {/* <div className="order-input">
        <span>天{star} ×</span>
        <input 
          type="number" 
          inputMode='decimal' 
          step={0.1}
          min="0" 
          onFocus={(e) => e.target.select()}
          value={multiplier[star-2]} 
          onChange={(e) => {
            const floatValue = parseFloat(e.target.value);
            const updatedMultiplier = [...multiplier];
            if (!isNaN(floatValue)) {
              updatedMultiplier[star-2] = floatValue;
              setMultiplier(updatedMultiplier);
            } else if (e.target.value === "") {
              updatedMultiplier[star-2] = "";
              setMultiplier(updatedMultiplier);
            }         
          }}  
          onBlur={(e) => {
            if (!e.target.value || isNaN(parseFloat(e.target.value))) {
              const updatedMultiplier = [...multiplier];
              updatedMultiplier[star-2] = 0;
              setMultiplier(updatedMultiplier);
            }
          }}
        />
        <span>1={price[star-2]}元</span>
      </div> */}
    </>   
    
  );
}


