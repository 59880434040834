import React from 'react';

const Ball = ({ number, selected, onClick }) => {
  return (
    <button
      className={`ball ${selected ? 'selected' : ''}`}
      onClick={() => onClick(number)}
    >
      {number}
    </button>
  );
};

export default Ball;